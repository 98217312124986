.list-item {
    border-top: 1px solid #b1b4b3;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;
}

.list-item__profile-image {
    font-family: "EgyptienneURWLig", sans-serif;
    font-weight: 100;
}

.list-item__information {
    flex: 1 1;
    margin: 0 0 0 16px;
}

.list-item__heading {
    font-family: "Futura Std Bold", sans-serif;
    font-size: 17px;
}

.list-item__subheading {
    margin: 5px 0 0 0;

    color: #54575a;
    font-family: "Futura Std Book", sans-serif;
    font-size: 11px;
}

.list-item__button {
    border: none;
    padding: 0;

    background-color: transparent;
}

.circle {
    margin: 0 10px 0 0;
}