.checklist {
	display: block;
}

.three-circles {
	display: flex;
}

.three-circles__circle {
	height: 7px;
	width: 7px;
	border-radius: 50%;
	border: 1px solid #b3b6b6;
	margin: 0 1px;
}

.comment-container {
	padding: 0 15px;
	border-top: 1px solid #e7e7e9;
	border-bottom: 1px solid #e7e7e9;
	background-color: #fff;
}

.comment-wrapper {
	border-bottom: 1px solid #e7e7e9;
	display: flex;
	padding: 15px 0;
}

.comment-wrapper:last-child {
	border-bottom: 0;
}

.checklist__item--dates {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	color: #b1b4b3;
	font-family: "Futura Std Book", sans-serif;
	border-top: 1px solid gray;
	border-bottom: 1px solid gray;
	padding-top: .5rem;
	padding-bottom: .5rem;
}

.checklist-comment__name {
	font-family: "Futura Std Bold", sans-serif;
	margin: 0;
}

.checklist-comment__comment {
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	margin-top: .5rem;
	margin-bottom: .5rem;
}

.checklist-comment__title {
	font-family: "EgyptienneURWLig", sans-serif;
}

.checklist__item-comment-input-container {
	display: flex;
	flex-direction: column;
	margin-top: 4rem;
}

.checklist__item-comment-submit-btn {
	width: min-content;
	background: gray;
	border: none;
	padding: 5px 10px;
	font-family: "Futura Std Book", sans-serif;
	margin-top: 1rem;
}

.checklist__item-comment-submit-btn-on {
	width: min-content;
	background: #ffb500;
	border: none;
	padding: 5px 10px;
	font-family: "Futura Std Book", sans-serif;
	margin-top: 1rem;
}

.checklist__item-new-comment-textarea {
	padding: 5px;
	border: 1px solid lightgray;
}

.checklist__item-comment-action {
	text-decoration: underline;
	cursor: pointer;
}

.checklist__item-assigned-container {
	display: flex;
	align-items: center;
	height: 50px;
}

.checklist__item-assigned-container p {
	margin: 0;
}

.checklist__item-assigned-teammate {
	display: flex;
	align-items: center;
	margin-left: 10px;
}

.checklist__item-assigned-teammate p {
	margin-left: 5px;
}

.checklist-item-remove-teammate {
	margin-left: auto;
	background: lightgray;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.checklist__fw-input-text {
	width: 100%;
	padding: 2px 5px;
	border: none;
	-webkit-box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
	-moz-box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
	box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
	margin-bottom: 1rem;
}

.checklist__fw-input-label {
	margin: 0;
	font-family: 'Futura Std Bold';
	margin-right: 5px;
}

.checklist__edit-cancel {
	padding: 5px 10px;
	background: gray;
	border: none;
	margin-right: 15px;
}

.checklist__edit-submit {
	padding: 5px 10px;
	background: #ffb500;
	border: none;
}
