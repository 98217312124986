.pagelink-sml{
  font-family: "Futura Std Bold", sans-serif;
  font-size: 0.75rem;
  font-weight: 800; 
  margin-right: 15px;
  display: inline-block;
  cursor: pointer;
}
.pagelink-sml.active {
  border-bottom: 2px solid #FFB500;  
}
.triangle{
  margin-right: 10px;
  color: #FFB500;
  font-size: 1rem;
  line-height: 1;
  display: inline-block;
}
.triangle.grey{
  color: #a5a5a5;
}
.trial-status {
    width: 22px;
}
.email-amount{
  background-color: grey;
  padding:0px 3px;
  border-radius:10px;
  color:white;
  font-size: 10px;
  font-weight:100;
  vertical-align: super;
  margin-left: 2px;
}
