.form-survey {
	position: relative;
	background: white;
}

.form-survey--disabled {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.form-survey__question {
	font-size: 14px;
	font-family: 'HumanistSlabserif712 BT Black', sans-serif;
	font-weight: bold;
}

.form-survey__group {
	border-top: 1px solid #a7a8aa;
	padding: 15px;
}

.form_input_error h3 {
	color: #c60000;
}

.form-survey__input-radio {
	display: none;
}

.form-survey__input-textarea {
	border-right: none;
	border-left: none;
	border-bottom: none;
	border-top: 1px solid #a7a8aa;
	margin-top: 10px;
	width: 100%;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form-survey__option-label-textarea {
	display: none;
}

.form-survey__input-radio:checked + .form-survey__option-radio {
	background-color: #ffb700;
}

.form-survey__option-radio {
	width: 18px;
	height: 18px;
	margin: 0;
	border: 1px solid #ccccd0;
	border-radius: 50%;
}

.form-survey__options-container {
	border-bottom: 1px solid #c8c7cc;
	border-top: 1px solid #c8c7cc;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 0;
}

.form-survey__options {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.form-survey__option {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 auto;
}

.form-survey__option-vertical {
	display: flex;
	padding: 10px 0;
	border-top: 1px solid #e5e5e7;
}

.form-survey__option-label-vertical {
	margin: 0 0 0 10px;
	order: 1;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
}

.form-survey__option-label {
	margin-bottom: 0;
	color: #b1b4b3;
	font-family: "Futura Std Book", sans-serif;
	text-transform: uppercase;
}

.form-survey__color-block {
	background-color: #f1f1f1;
	height: 30px;
	width: 100%;
}
