.weekly-engagement__header-heading {
    position: relative;
    z-index: 1;
    padding: 0 0 0 30px;
    width: 52%;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 25px;
    line-height: 1;
}

.weekly-engagement__header-heading-subtext {
    font-family: "Futura Std Bold", sans-serif;
}

.weekly-engagement__header-text {
    margin: 0;
    padding: 0 0 0 30px;
    width: 58%;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 16px;
}

.weekly-engagement__header-image-container {
    margin: -70px 0 0 0;
    width: 100%;
}

.weekly-engagement__header-image {
    width: 100%;
}

.weekly-engagement__paragraph-container {
    padding: 40px 30px 40px 30px;

    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.weekly-engagement__section {
    padding: 40px 30px 50px 0;
    position: relative;

    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.weekly-engagement__section-heading {
    margin: 0 0 12px 0;

    font-size: 32px;
    font-family: "EgyptienneURWLig", sans-serif;
    line-height: 1;
    text-align: right;
}

.weekly-engagement__section-heading-subtext {
    font-family: "Futura Std Bold", sans-serif;
}

.weekly-engagement__button-container {
    padding: 0 30px 30px 30px;

    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.weekly-engagement__mission {
    padding: 40px 30px 50px 30px;
    position: relative;
}

.weekly-engagement__mission-title {
    color: #ffb500;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 20px;
}

.weekly-engagement__mission-text {
    padding: 20px 0 0 0;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 23px;
    line-height: 1.2;
    text-align: center;
}