.verbatim {
    border: 1px solid #b1b4b3;
    margin: 0 0 50px 0;
    padding: 20px 50px 50px 50px;
    position: relative;

    border-radius: 25px;
}

.verbatim-quote-image-container {
    margin: 0 0 10px 0;

    text-align: center;
}

.verbatim-quote {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 18px;

    margin: 0 0 15px 0;
}

.verbatim-person {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.verbatim-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
}