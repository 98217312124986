.email-preview__header-right {
    position: relative;
    top: 55px;
    width: 41%;
}

.email-preview-border {
    height: 30px;
    width: 100%;

    background: linear-gradient(90deg, rgba(255,179,0,1) 40%, rgba(178,113,37,1) 100%);
}

.email-preview-border-wrapper {
    padding: 0 30px 0 0;
}

@media screen and (min-width: 768px) {
    .email-preview__header-right {
        top: 20px;
    }
}