.overlay__header {
	padding: 10px 15px;
	display: flex;
	align-items: center;

	background-color: #535659;
}

.overlay__header--no-button {
	justify-content: center;
}

.overlay__header--button {
	justify-content: space-between;
}

.overlay__heading {
	margin: 0;
	margin-left: 30px;

	color: #fff;
	font-size: 22px;
	font-family: "EgyptienneURWLig", sans-serif;
}

.overlay__header--no-button .overlay__heading {
	margin: 0;
}

.overlay__header--no-button .overlay__close {
	position: absolute;
	left: 15px;
}

.overlay__close {
	color: #fff;
	cursor: pointer;
	font-size: 22px;
}

.overlay__button {
	border-radius: 25px;
	padding: .105rem 1.1rem;
	border: none;

	background-color: #ffb700;
	color: #000;
	font-size: 0.9rem;
	opacity: .5;
	font-family: 'FuturaStd Book', sans-serif;
	letter-spacing: -.5px;
	outline: none;
	text-transform: uppercase;
}

.editing {
	opacity: 1;
}
