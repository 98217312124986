.button {
    border: none;
    min-height: 40px;
    min-width: 120px;

    font-size: 17px;
    font-family: "Futura Std Book", sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.button--white {
	background-color: transparent;
	border: 1px solid #54575a;
}

.button--gray {
    background-color: #B1B4B3;
    color: #000000;
}

.button--cancel {
    background-color: #54575a;
    color: #fff;
}

.button--submit {
    background-color: #ffb500;
    color: #000;
}