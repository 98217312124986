.surgeon-wrapper {
	background-color: #fff;
	border-top: 1px solid #c8c7cc;
	border-bottom: 1px solid #c8c7cc;
	max-height: 200px;
	overflow-x: hidden;
}

.surgeon-container {
	border-bottom: 1px solid #c8c7cc;
	display: flex;
	align-items: center;
	margin-left: 15px;
}

.surgeon-container:last-child {
	border-bottom: 0;
}

.surgeon {
	padding: 10px;
}

.surgeon__x-icon {
	cursor: pointer;
	font-size: 1.3rem;
}

.surgeon__name {
	margin: 0;
	font-family: "Futura Std Bold", sans-serif;
	font-weight: 900;
	line-height: 1;
}

.surgeon__specialty {
	margin: 3px 0 0 0;
	color: #b1b4b3;
	font-family: "Futura Std Book", sans-serif;
	font-size: 0.7rem;
	line-height: 1;
}
