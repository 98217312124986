.image-editing__image {
    height: 136px;
    width: 233px;
    margin: 0 auto;
    position: relative;
}

.image-editing__rotate-button {
    border: none;
    padding: 0;

    background-color: transparent;
}

.image-editing__controls {
    margin: 10px 0 0 0;

    text-align: center;
}

.image-editing__rotate-button-left {
    margin: 0 20px 0 0;
}

.image-editing__rotate-button-right {
    margin: 0 40px 0 0;
}

.image-editing__small-icon {
    font-size: 16px;
}

.image-editing__slider {
    margin: 0 20px;
}

.image-editing__large-icon {
    font-size: 24px;
}