.button {
    border: 1px dashed #b1b4b3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    width: 100%;

    background-color: transparent;
    border-radius: 25px;
}

.button__icon {
    margin: 0 10px 0 0;
    width: 25px;
}

.button__text {
    color: #b1b4b3;
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 18px;
}