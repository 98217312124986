.button {
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 10px;
    top: -20px;
    right: -20px;
    z-index: 2;

    background-color: #fff;
    border-radius: 50%;
    color: #000000;
    font-size: 20px;
}