h4.section-title-single {
  font-family: "Futura Std Bold", sans-serif;
  font-weight: 900;
  font-size: 1.7rem;
}
.title-tab-single{
  border-radius: 0 0 0 20px;
  background-color: #fff;
  box-shadow: 0px 8px 8px #dcd9d9;
}
.tab-title {
    font-family: "Futura Std Bold", sans-serif;
    color: #b1b1b1;
    font-size: 1.1rem;
}
.list-style{
    list-style: disc;
}