.send-engagement-email-container {
    padding: 32px 30px 30px 30px;
}

.send-engagement-email__title {
    border-bottom: 1px solid #abaead;
    padding: 0 0 5px 0;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 31px;
    line-height: 31px;
}

.send-engagement-email__text {
    margin: 28px 0 10px 0;

    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
    font-size: 14px;
    line-height: 22px;
}

.send-engagement-email__subtext {
    margin: 0;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 12px;
    line-height: 15px;
}

.send-engagement-email__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 124px;
    margin: 35px 0 20px 0;
    position: relative;

    text-align: center;
}

.send-engagement-email__image {
    cursor: pointer;
    transition: all 0.6s ease;
}

.send-engagement-email__image--yellow {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;

    transform: scale(0.9);
}

.send-engagement-email__image--yellow:hover {
    transform: scale(1);
}

.send-engagement-email-image--gray {
    transform: scale(1.1);
}

.hover-over-cancel-button .send-engagement-email__image--yellow {
    opacity: 0;
}

.hover-over-cancel-button .send-engagement-email__image--gray {
    transform: scale(1);
}

.send-engagement-email__button-container {
    text-align: center;
}

.send-engagement-email__button {
    padding: 7px 20px;

    background-color: transparent;
    border-radius: 30px;
    font-family: "Futura Std Book", sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.send-engagement-email__button--gray {
    border: 1px solid #54575a;

    color: #54575a;
}

.send-engagement-email__button--yellow {
    border: 1px solid #ffb500;
    display: inline-block;

    color: #ffb500 !important;
}

.send-engagement-email__button--yellow:hover {
    color: #ffb500 !important;
}

.send-engagement-email__button--gray:hover {
    background-color: #54575a;
    color: #fff;
}