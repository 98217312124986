.header__text-light {
    font-family: "EgyptienneURWLig", sans-serif;
}

.header__text-bold {
    font-family: "Futura Std Bold", sans-serif;
}

.ribbon {
    height: 17px;
    margin: 10px 0 10px 0;
    
    background: #fdc134;
    background: -moz-linear-gradient(left, #fdc134 30%, #c67433 100%);
    background: -webkit-linear-gradient(left, #fdc134 30%,#c67433 100%);
    background: linear-gradient(to right, #fdc134 30%,#c67433 100%);
}

.ribbon--margin {
    margin: 14px 0 15px 0;
}

.email-preview__paragraphs-container {
    padding: 22px 30px 30px 30px;
    
    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.email-preview__paragraph {
    margin-bottom: 10px;

    font-size: 16px;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;  
    line-height: 1.2;
}