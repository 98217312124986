.PDF-viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;

    background-color: rgb(245, 245, 245);
}

.PDF-viewer__page-container {
    padding: 3vh 5%;
    overflow: hidden;
}

.PDF-viewer__page {
    box-shadow: 0 0 10px #afafaf;
}