.trial-statistic {
    border: 1px solid #b1b4b3;
    padding: 20px 0 50px 0;
    position: relative;
    width: 47%;

    border-radius: 25px;
    text-align: center;
}

.trial-statistic__number {
    color: #54575a;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 70px;
    line-height: 1;
}

.trial-statistic__text {
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 32px;
    line-height: 1;
}

.trial-statistic__subtext {
    font-family: "Futura Std Bold", sans-serif;
    font-size: 28px;
    line-height: 1;
}

.trial-statistic__buttons {
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
}