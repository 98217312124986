.stat-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
}

.stat-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
}

.stat-info {
    line-height: 0.8;
}

.stat__number {
    color: #54575a;
    font-family: "Futura Std Bold", sans-serif;
}

.stat__type {
    color: #000000;
    font-family: "EgyptienneURWLig", sans-serif;
}

.stat__percentage {
    color: #54575a;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 32px;
}

.stat__percentage-bar {
    height: 2px;
    margin-bottom: 3px;
}