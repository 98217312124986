.las.la-star.place{
    color: transparent;
}

.la-star.place.favorite{
    font-size: 7px;
    line-height: 7px;
    color: white;
    background-color: #f5b840;
    border: 1px solid #fff;
    padding: 1.5px 2px 2px 2px;
    border-radius: 6px;    
}
.area{
    width: 5px;
    height: 5px;
    cursor: pointer;
    z-index: 40;
    margin-top: -13px;
    margin-left: 18px;
}
