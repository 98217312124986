.button-container {
    text-align: center;
}

.button {
    border: none;
    margin: 10px 0;
    padding: 5px 20px;

    background-color: #ffb500;
    border-radius: 18px;
    cursor: pointer;
    font-family: "Futura Std Book", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}