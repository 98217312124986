.navbar-container {
	position: relative;
}

.navbar {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 60px;
	margin: auto;
	max-width: 900px;
	padding: 0 14px;
	position: fixed;
	z-index: 3;
	width: 100%;
	background-color: #fff;
	border-bottom-left-radius: 20px;
	box-shadow: 0px 20px 40px 0px rgba(35, 31, 32, 0.2);
}

.navbar-gradient {
	height: 60px;
	margin: auto;
	position: fixed;
	top: 2px;
	z-index: 2;
	max-width: 900px;
	width: 100%;
	background: linear-gradient(90deg, rgba(255, 179, 0, 1) 40%, rgba(178, 113, 37, 1) 100%);
	border-bottom-left-radius: 20px;
}

.navbar__back-button-container {
	display: flex;
	align-items: center;
	justify-self: center;
	background-color: #fff;
	font-size: 12px;
}

.navbar__back-button-text {
	cursor: pointer;
	font-family: "Futura Std Book", serif;
}

.navbar__back-button-arrow {
	color: #ffb500;
}

.navbar__title {
	margin: 0 20px;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 12px;
	overflow: auto;
	word-break: break-word;
	text-align: center;
}

.navbar__mail-button {
	border: 1px solid #ffb500;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25px;
	width: 25px;
	margin-left: auto;
	border-radius: 50%;
	color: #ffb500;
}
