.container {
    padding: 32px 30px 30px;
}

.title {
    display: flex;
    align-items: center;
    margin: 0;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
}

.title--line {
    flex: 1;
    height: 1px;
    margin: 0 0 0 20px;

    background-color: #54575a;
}

.form {
    margin: 20px 0 0 0;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
}

.label {
    margin: 0 0 5px 0;

    font-family: "Futura Std Book", sans-serif;
    font-size: 10px;
    text-transform: uppercase;
}

.input, .select {
    border: none;
    height: 30px;
    padding: 0 12px;
    
    -webkit-box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
    -moz-box-shadow: inset 0px 0px 6px 0px rgba(0,0,0,0.25);
    box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
    font-size: 16px;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.select {
    background-color: #fff;
}

.form-group--buttons {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 0 0;
}

.button {
    border: none;
    height: 40px;
    min-width: 120px;
    padding: 0 20px;

    font-family: "Futura Std Book", sans-serif;
    font-size: 17px;
    line-height: 42px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.button--cancel {
    background-color: #54575a;
    color: #fff;
}

.button--submit {
    background-color: #ffb500;
    color: #000;
}