.form__title {
	border-bottom: 1px solid #54575a;
	margin: 0;
	padding: 18px 20px;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 20px;
}

.form__groups-container {
	height: 390px;
	padding: 20px 20px 0 20px;
	overflow-y: scroll;
}

.form__group {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	position: relative;
}

.form__group-split {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.profile-picture__container {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	margin: 0 auto;
	width: 60px;
	background-color: #b1b4b3;
	color: #fff;
	font-size: 20px;
}

.profile-picture__input-container {
	margin: 10px 0 20px 0;
	text-align: center;
}

.profile-picture__label {
	border: 1px solid #54575a;
	margin: 0;
	padding: 3px 25px;
	border-radius: 20px;
	cursor: pointer;
	font-family: "Futura Std Book", sans-serif;
	font-size: 12px;
	letter-spacing: 0.55px;
	text-transform: uppercase;
}

.form__label {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	font-family: "Futura Std Book", sans-serif;
	font-size: 10px;
	line-height: 16px;
	text-transform: uppercase;
}

.form__influence-circles-container {
	display: flex;
	justify-content: space-between;
}

.form__influence-circle {
	border: 1px solid gray;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	cursor: pointer;
}

.form__influence-circle--active.form__influence-circle:nth-child(1) {
	border: none;
	background-color: #c1cbdf;
}

.form__influence-circle--active.form__influence-circle:nth-child(2) {
	border: none;
	background-color: #8fa2c5;
}

.form__influence-circle--active.form__influence-circle:nth-child(3) {
	border: none;
	background-color: #6683ae;
}

.form__influence-circle--active.form__influence-circle:nth-child(4) {
	border: none;
	background-color: #3c699b;
}

.form__influence-circle--active.form__influence-circle:nth-child(5) {
	border: none;
	background-color: #045389;
}

.form__select {
	border: none;
	border-bottom: 1px solid #54575a;
	margin-bottom: 20px;
	background-color: #fff;
}

.form__label-optional {
	color: #b1b4b3;
}

.form__input {
	border: none;
	border-bottom: 1px solid #54575a;
	position: relative;
	width: 100%;
	z-index: 2;
	
	background-color: transparent;
	color: #54575a;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
}

.form__input--margin-top {
	margin-top: 20px;
}

.form__buttons {
	border-top: 1px solid #54575a;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 20px;
}

.form__button {
	border: none;
	display: flex;
	align-items: center;
	padding: 0;
	background-color: transparent;
	font-size: 12px;
	text-transform: uppercase;
}

.form__button-icon {
	font-size: 14px;
}

.form__button--submit {
	color: #ffb500;
}

.form__button--submit .form__button-icon {
	margin-left: 4px;
}

.form__button--cancel {
	color: #b83c27;
}

.form__button--back {
	color: #b1b4b3;
}

.form__button--cancel .form__button-icon {
	margin-right: 4px;
}

.form__select-type-container {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	margin-bottom: 35px;
}

.form__select-type-wrapper {
	width: 50%;
}

.form__select-type {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin: 0;
	width: 100%;
	background-color: #e7e8e8;
	cursor: pointer;
	font-family: "Futura Std Book", sans-serif;
	font-size: 12px;
	text-transform: uppercase;
}

.form__select-type--selected-surgeon {
	height: 50px;
	background-color: #54575a;
	border-radius: 8px;
	color: #fff;
	box-shadow: 6px 8px 17px rgb(0 0 0 / 20%);
}

.form__select-type--selected-stakeholder {
	height: 50px;
	background-color: #ffb500;
	border-radius: 8px;
	box-shadow: 6px 8px 17px rgb(0 0 0 / 20%);
	color: #000;
}

.form__select {
	border-bottom: 1px solid #54575a;
	color: #54575a;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
}

.form__select-images {
	display: flex;
	flex-wrap: wrap;
	height: 390px;
	padding: 10px;
	overflow-y: auto;
}

.form__select-image-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0 0 25px 0;
	padding: 0 7px;
	width: 33%;
}

.form__select-image-border {
	border: 1px solid #54575a;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 74px;
	padding: 5px;
	width: 100%;
	border-radius: 6px;
	cursor: pointer;
}

.form__select-image {
	height: 45px;
	width: 45px;
}

.form__select-image-text {
	margin-top: 7px;
	font-size: 11px;
	font-family: "Futura Std Book", sans-serif;
	text-align: center;
	text-transform: uppercase;
}

.form__progress-dots {
	display: flex;
}

.form__progress-dot {
	border: 1px solid #54575a;
	height: 7px;
	margin-right: 9px;
	width: 7px;
	border-radius: 50%;
}

.form__progress-dot--active {
	background-color: #54575a;
}

.form__progress-dot:last-child {
	margin: 0;
}
