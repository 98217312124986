.loading-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 0;
	text-align: center;
	height: 30vh;
	width: 100%;
}

.loading-text {
	margin: 10px 0 0 0;
	color: black;
	font-family: "Futura Std Book", sans-serif;
	text-transform: uppercase;
}

.loading-indicator {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.loading-indicator div {
	animation: loading-indicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}

.loading-indicator div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #ffb500;
	margin: -4px 0 0 -4px;
}

.loading-indicator div:nth-child(1) {
	animation-delay: -0.036s;
}

.loading-indicator div:nth-child(1):after {
	top: 63px;
	left: 63px;
}

.loading-indicator div:nth-child(2) {
	animation-delay: -0.072s;
}

.loading-indicator div:nth-child(2):after {
	top: 68px;
	left: 56px;
}

.loading-indicator div:nth-child(3) {
	animation-delay: -0.108s;
}

.loading-indicator div:nth-child(3):after {
	top: 71px;
	left: 48px;
}

.loading-indicator div:nth-child(4) {
	animation-delay: -0.144s;
}

.loading-indicator div:nth-child(4):after {
	top: 72px;
	left: 40px;
}

.loading-indicator div:nth-child(5) {
	animation-delay: -0.18s;
}

.loading-indicator div:nth-child(5):after {
	top: 71px;
	left: 32px;
}

.loading-indicator div:nth-child(6) {
	animation-delay: -0.216s;
}

.loading-indicator div:nth-child(6):after {
	top: 68px;
	left: 24px;
}

.loading-indicator div:nth-child(7) {
	animation-delay: -0.252s;
}

.loading-indicator div:nth-child(7):after {
	top: 63px;
	left: 17px;
}

.loading-indicator div:nth-child(8) {
	animation-delay: -0.288s;
}

.loading-indicator div:nth-child(8):after {
	top: 56px;
	left: 12px;
}

@keyframes loading-indicator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
