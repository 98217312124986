.form {
	padding: 0 15px;
}

.form__header-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.form__title {
	margin: 0;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 24px;
}

.form__button {
	border: none;
	padding: 3px 18px;
	background-color: #ffb500;
	border-radius: 18px;
	font-family: "Futura Std Book", sans-serif;
	font-size: 12px;
	text-transform: uppercase;
}

.form__image-container {
	padding: 0;
	width: 25px;
	background-color: transparent;
	border: none;
}

.form__image {
	width: 100%;
}

.checkmark-circle {
	border: 1px solid #000;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	color: #fff;
}

.list__item-radio.list__item--active + .checkmark-circle {
	background-color: #ffb600;
}

.list__item--opacity-low {
	opacity: 0.4;
}

.Form__section-container {
	margin: 2rem 0;
}

.form_final-submit {
	background: #ffb500;
	padding: 5px 15px;
	text-transform: uppercase;
	border: none;
}
