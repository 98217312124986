.list__item--opacity-low {
    opacity: 0.4;
}

.checkmark-circle {
    border: 1px solid #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;

    color: #fff;
}

.list__item-radio.list__item--active + .checkmark-circle, 
.list__item-radio[type=checkbox]:checked + .checkmark-circle {
    background-color: #ffb600;
}

.list__item--opacity-low {
    opacity: 0.4;
}

.add-email-container {
    margin: 10px 0;

    display: flex;
    flex-direction: column;
}

.add-email__form-elements {
    display: flex;
    align-items: center;
}

.add-email__input {
    border: none;
    border-bottom: 1px solid #54575a;
    margin-right: 5px;

    color: #54575a;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 14px;
}

.add-email__input-error {
    color: #ff0000;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 12px;
}

.add-email__button {
    border: none;
    padding: 5px 20px;

    background-color: #ffb500;
    border-radius: 18px;
    cursor: pointer;
    font-family: "Futura Std Book", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}