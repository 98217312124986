.form-duplicate-pop-up {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.form-duplicate {
	padding: 15px;
}

.form-duplicate__title {
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 30px;
}

.form-duplicate__card-group {
	display: flex;
	flex-direction: column;
	margin: 26px 0;
}

.form-duplicate__submit {
	border: none;
	margin-bottom: 18px;
	padding: 12px 0;
	width: 100%;
	background-color: #ffb500;
	font-family: 'FuturaStd Book', sans-serif;
	text-transform: uppercase;
}

.form-duplicate__edit {
	border: none;
	margin-bottom: 18px;
	padding: 12px 0;
	width: 100%;
	background-color: #54575a;
	color: white;
	font-family: 'FuturaStd Book', sans-serif;
	text-transform: uppercase;
}

.form-duplicate__cancel {
	border: 1px solid #54575a;
	padding: 12px 0;
	width: 100%;
	background-color: #fff;
	font-family: 'FuturaStd Book', sans-serif;
	text-transform: uppercase;
}
