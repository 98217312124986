.list__item {
	border-top: 1px solid #dee2e6;
	display: flex;
	align-items: center;
	padding: .75rem 0 .7rem 0;
	width: 100%;
}

.list__item:last-child,
.list li:last-child a.list__item,
.list div:last-child label.list__item {
	border-bottom: 1px solid #dee2e6;
}

.list li a.list__item,
.list div label.list__item {
	border-bottom: none;
}

.list__sort {
	border: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-left: auto;
	padding: 0;

	background-color: transparent;
	color: #555;
	font-size: 12px;
	letter-spacing: 0.4px;
}

.list__sort-text {
	margin-right: 4px;
	font-family: "Futura Std Book", sans-serif;
}

.list__item-action {
	border: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	padding: 8px;
	background-color: #f5b840;
	border-radius: 50%;
	color: #000;
	font-size: 12px;
}

.list__item-content {
	flex: 1 1 0;
	font-size: 12px;
}

.list__item-content.-line-height {
	line-height: 1.3;
}

.list__item-name {
	color: #000;
	font-family: "Futura Std Bold", sans-serif;
	font-size: .9rem;
}

.list__item-recipients {
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	letter-spacing: .4px;
}

.list__item-institution,
.list__item-gray {
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	letter-spacing: .4px;
	text-transform: uppercase;
}

.list__item-description {
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	letter-spacing: .4px;
}

.list__item-date, 
.list__item-light-gray {
	color: #b1b4b3;
	font-family: "Futura Std Book", sans-serif;
	letter-spacing: .4px;
}

.link__item-icon {
	align-self: center;
	color: #000;
}

.list__item-status {
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	width: 8px;
	margin-right: 5px;
}

.list__item-status-image {
	width: 100%;
	margin-top: 6px;
}

.list__item-profile-image {
	margin-right: 10px;
}

.link__action-item {
	background: gray;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: white;
	padding: 10px;
}

.link__item-icon-white {
	color: #fff;
}

.list .list-audio-item:first-child {
	border-top: none;
}

.list-audio-item {
	cursor: pointer;
}