.create-trial-header {
    margin: 70px 0 30px 0;
    padding: 15px 0;

    box-shadow: 0 11px 11px -6px rgb(0 0 0 / 8%);
}

.create-trial__cancel-button {
    border: none;
    display: flex;
    align-items: center;
    padding: 0 15px;

    background-color: transparent;
    font-size: 12px;
    font-family: "Futura Std Book", sans-serif;
    text-transform: uppercase;
}

.create-trial__cancel-icon {
    padding-right: 1px;

    color: #b83c27;
}

.create-trial__title {
    margin: 10px 0 25px 0;
    padding: 0 15px;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 44px;
    line-height: 1;
}

.form-navigation {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;

    color: #b1b4b3;
    font-family: "Futura Std Book", sans-serif;
    font-size: 11px;
    text-transform: uppercase;
}

.form-navigation__container.active {
    color: #000;
}

.form-navigation__container.active .form-navigation__right-arrow-icon {
    color: #ffb500;
}

.form-navigation__right-arrow-icon {
    margin: 0 5px;
}