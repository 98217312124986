.trial-recap__header {
    display: flex;
    padding: 0 30px 0 0;
}

.trial-recap__header-heading {
    margin: 0 0 0 30px;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 25px;
    line-height: 1;
}

.trial-recap__header-heading-subtext {
    margin: 0 0 10px 30px;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 25px;
    line-height: 1;
}

.trial-recap__header-item {
    width: 50%;
}

.trial-recap__header-image-container {
    margin: 0 0 15px 20px;
}

.trial-recap__header-image {
    width: 100%;
}

.trial-recap__quote {
    border-bottom: 1px solid #000;
    margin: 0 30px 0 30px;
    padding: 0 0 10px 0;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 18px;
}

.trial-recap__paragraphs-container {
    padding: 30px;
}

.trial-recap__gallery-section {
    position: relative;
}

.trial-recap__gallery-section-header {
    padding: 0 30px 0 0;
}

.trial-recap__gallery-section-heading {
    padding: 0 0 0 30px;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 25px;
}

.trial-recap__gallery-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.trial-recap__gallery-container {
    padding: 0 30px 30px 30px;
    
    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.trial-recap__gallery-item {
    width: 33%;
}

.trial-recap-gallery__text {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 14px;
}

.trial-recap__responses {
    padding: 40px 0 100px 30px;
    position: relative;
}

.trial-recap__responses-title {
    font-family: "EgyptienneURWLig", sans-serif;
    line-height: 1;
}

.trial-recap__responses-subtext {
    font-family: "Futura Std Bold", sans-serif;
    line-height: 1;
}

.trial-recap__counters-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 244px;

    background-image: url(/images/email/inte-trial-complete-banner.png);
    background-size: cover;
    background-position: center;
}

.trial-recap__counter-number {
    color: #54575a;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 70px;
}

.trial-recap__counter-participating {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 20px;
    margin: 0 0 10px 0;
    line-height: 1;
}

.trial-recap__counter-type {
    font-family: "Futura Std Bold", sans-serif;
    font-size: 20px;
    margin: 0 0 10px 0;
    line-height: 1;
}

.trial-recap__person-quote-container {
    padding: 50px 30px;

    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.trial-recap__person-quote-icon-container {
    text-align: center;
}

.trial-recap__person-quote {
    padding: 20px 0 0 0;
    margin: 0 0 25px 0;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 16px;
}

.trial-recap__person-quote-name {
    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
    font-size: 16px;
}

.trial-recap__statistics-wrapper {
    padding: 30px 0 30px 30px;
    position: relative;
}

.trial-recap__statistics-wrapper-title {
    margin: 0;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 25px;
}

.trial-recap__statistics-wrapper-subtext {
    font-family: "Futura Std Bold", sans-serif;
}

.trial-recap__pie-chart-container {
    display: flex;
    padding: 50px 30px 0 30px;
}

.trial-recap__pie-chart {
    padding: 0 0 0 15px;
}

.trial-recap__question {
    border-bottom: 1px solid #cbcdcc;
    padding: 0 0 15px 0;
    
    font-family: "Futura Std Bold", sans-serif;
}

.trial-recap__pie-chart-label-container {
    display: flex;
}

.trial-recap__pie-chart-label-percentage {
    font-family: "Futura Std Bold", sans-serif;
    font-size: 19px;
}

.trial-recap__pie-chart-label-percentage--purple-one {
    color: #774890;
}

.trial-recap__pie-chart-label-percentage--purple-two {
    color: #8b67a1;
}

.trial-recap__pie-chart-label-percentage--purple-three {
    color: #a085b4;
}

.trial-recap__pie-chart-label-percentage--purple-four {
    color: #baa8ca;
}

.trial-recap__pie-chart-label-percentage--green-one {
    color: #40797d;
}

.trial-recap__pie-chart-label-percentage--green-two {
    color: #82a3a9;
}

.trial-recap__pie-chart-label-text {
    font-family: "Futura Std Book", sans-serif;
    font-size: 12px;
}

.trial-recap__horizontal-bar-graph-container {
    padding: 50px 30px 0 30px;
}

.trial-recap__horizontal-bar-graph-list-image {
    display: flex;
}

.trial-recap__horizontal-bar-graph-image {
    width: 100%;
}

.trial-recap__horizontal-bar-graph-list {
    margin-right: 12px;
}

.trial-recap__horizontal-bar-graph-list-item {
    font-family: "Futura Std Book", sans-serif;
    font-size: 11px;
}

.trial-statistics-container--padding {
    margin: 40px 30px 30px 30px;
}

.engagement-email-verbatim-container {
    margin: 0 30px 30px 30px;
}