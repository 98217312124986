.email-results-container {
    margin-top: 70px;
}

.email-results__title-container {
    border-bottom: 1px solid #54575a;
    padding: 10px 15px 6px 15px;

    background-color: #efeff4;
}

.email-results__title {
    margin: 0;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 24px;
}

.email-results-section__stats-header {
    border-bottom: 1px solid #54575a;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 15px;
    padding: 5px 15px 5px 0;
}

.email-results-section__stats-header-left {
    color: #54575a;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 24px;
}

.email-results-section__header-light {
    color: #b1b4b3;
    font-family: "Futura Std Book", sans-serif;
}

.email-results-section__header-image-container {
    width: 20px;
}

.email-results-section__header-image {
    width: 100%;
}

.email-results-section__stats-container {
    border-bottom: 1px solid #c8c7cc;
}

.email-results-section__stat-container {
    margin: 0 0 0 15px;
    padding: 0 15px 0 0;

    border-bottom: 1px solid #c8c7cc;
}

.email-results-section__stat-container:last-child {
    border: none;
}

.email-results-section__header {
    padding: 34px 0 5px 15px;

    background-color: #efeff4;
    border-bottom: 1px solid #54575a;
}

.email-results-section__header-text {
    margin: 0;

    color: #54575a;
    font-family: "Futura Std Book", sans-serif;
    font-size: 13px;
    letter-spacing: -0.4px;
    text-transform: uppercase;
}

.email-results-section__header-text-light {
    color: #b1b4b3;
}

.email-results-section__list-item-text-light {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    color: #b1b4b3;
}

.email-results-section__list {
    border-bottom: 1px solid #c8c7cc;
    margin: 0;
}

.email-results-section__list-item {
    border-bottom: 1px solid #c8c7cc;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 15px;
    padding: 15px 15px 15px 0;

    font-size: 12px;
}

.email-results-section__list-item.-recipients {
    align-items: center;
    padding: 10px 15px 10px 0;
}

.email-results-section__list-item:last-child {
    border: none;
}

.email-results-section__list-item-text {
    color: #54575a;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.email-results-section__list-item-name {    
    font-family: "Futura Std Bold", sans-serif;
    font-size: 16px;
}

.email-results-section__list-item-circles-container {
    display: flex;

    margin: 2px 0 8px 0;
}

.email-results-section__list-item-circle {
    margin: 0 10px 0 0;
    height: 9px;
    width: 9px;
}

.email-results-section__list-item-image-container {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    position: relative;
    width: 30px;
}

.email-results-section__list-item-icon-notification {
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    height: 8px;
    width: 8px;

    background-color: #1c5687;
}

.email-results-section__list-item-image-container.-gray {
    border: 1px solid #c8c7cc;
}

.email-results-section__list-item-image-container.-yellow {
    border: 1px solid #ffb500;
}

.email-results-section__list-item-image-container.-red {
    border: 1px solid #b83c27;
}

.email-results-section__list-item-email {    
    color: #54575a;
    font-family: "Futura Std Book", sans-serif;
}

.email-results-section__list-item-image {
    width: 50%;
}

.email-results-section__list-item-left {
    display: flex;
    align-items: center;
}

.email-results-section__list-item-left-image-container {
    margin-right: 5px;
    width: 25px;
}

.email-results-section__list-item-left-image {
    width: 100%;
}

.email-results-preview__header {
    display: flex;
    justify-content: space-between;

    padding: 0 30px;
}

.email-results-preview__header-left {
    width: 47%;
}

.email-results-preview__header-right {
    width: 41%;
}

.email-results-preview__title {
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 24px;
    line-height: 1.2;
}

.email-results-preview__headline {
    margin: 0;
    
    font-family: "Futura Std Bold", sans-serif;
    font-size: 24px;
}

.email-results-preview__header-image {
    margin: 0 0 15px 0;
    width: 100%;
}

.email-results-border {
    height: 20px;
    margin: 10px 30px 0 0;

    background: linear-gradient(90deg, rgba(255,179,0,1) 40%, rgba(178,113,37,1) 100%);
}

.email-results-preview__subsection-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 0 0 30px;
}

.email-results-preview__bold-headline {
    margin: 0 0 10px 0;
    padding: 0 30px 0 0;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 18px;
}

.email-results-border.-thin {
    margin: 0;
    height: 15px;
    width: 100%;
}

.email-results-preview__subsection {
    padding: 50px 0;
}

.email-results-preview__image-container {
    display: block;
    padding: 25px 30px 0 30px;
}

.email-results-preview__image {
    width: 100%;
}

.email-results__preview-container {
    background-color: #f1f1f1;
}