.error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    
    color: #B83C27;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 15px;
    text-align: center;
    word-break: break-word;
}