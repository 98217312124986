.input-icon i {
  position: absolute;
  margin-left: 10px;
  margin-top: 12px;
  opacity: 0.3;
}
.input-icon {
  width: 100%;
}
.search {
  padding: 10px 0 0 0;
  position: relative;
}

input.search__input {
  padding: 0 0 0 30px;

  border-radius: 25px;
  font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.mt-10p{
  margin-top: 10px;
}