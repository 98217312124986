.header {
    padding: 0 0 30px 30px;
    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.header__heading {
    width: 50%;

    font-size: 35px;
    line-height: 1;
}

.header__description {
    width: 50%;

    line-height: 21px;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 18px;
}

.header__image-container {
    margin: -40px 0px;
    width: 100%;
}

.header__image-container--margin {
    margin: -50px 0 -10px 0px;
}

.header__image {
    width: 100%;
}

.information-container {
    padding: 50px 30px;

    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.information {
    display: flex;
    padding: 0 0 50px 0;
}

.information__image-container {
    width: 20%;
}

.information__image {
    width: 100%;
}

.information__right {
    flex: 1;
}

.information__title {
    border-bottom: 1px solid #b1b4b3;
    padding: 0 0 4px 12px;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 24px;
    line-height: 1;
}

.information__text-container {
    line-height: 19px;
    padding: 7px 0 0 12px;
}

.information__text-light {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.information__text-bold {
    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
}

.section {
    padding: 50px 30px 70px 30px;

    font-size: 18px;
    line-height: 21px;
}

.section__text-bold {
    font-family: "Futura Std Bold", sans-serif;
}

.section__text-light {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}