.search {
    border: 1px solid #b1b4b3;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    margin-right: 17px;
    position: relative;
    
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.search__input {
    border: none;
    flex: 1;
    padding: 5px 0;
    margin: 0 4px;

    font-size: 14px;
}

.search__icon {
    padding-left: 10px;
}

.search__clear-button {
    border: none;
    padding: 0 8px 0 0;

    background-color: transparent;
}