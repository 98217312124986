.container {
	height: 100%;
	min-height: 100vh;
	width: 100%;
	background-color: #efeff4;
	padding-top: 70px;
}

.form {
	margin: 10px 0 0 0;
	position: relative;
}

.form__label {
	margin: 0 0 0 15px;
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 24px;
}

.form .form__input--icon {
	border-right: none;
	padding: .375rem .75rem;
	background-color: #fff;
}

.form .form__input {
	padding: 1.3rem 0;
	border-left: none;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form .form__button {
	border: 1px solid #eee;
	padding: 12px 0;
	margin-top: 40px;
	width: 100%;
	background-color: #fff;
	color: #FFB500;
	text-transform: uppercase;
	font-family: "Futura Std Book", sans-serif;
}

.form .form__button:hover {
	color: #FFB500;
	text-decoration: underline;
}

.form .form__form-group {
	margin: 0;
}

.drop-down-container {
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	left: 0;
	right: 0;
	padding: 0 20px;
	width: 100%;
	max-width: 900px;
	max-height: 200px;
	overflow-x: hidden;
	z-index: 3;
	background-color: #fff;
}

.drop-down {
	border-bottom: 1px solid #eee;
	padding: 10px 0;

	cursor: pointer;
}

.drop-down__email,
.drop-down__specialty {
	margin: 0;
	color: #b1b4b3;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 0.8rem;
}

.drop-down__name {
	margin: 0;
	color: #54575a;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 0.9rem;
	text-transform: capitalize;
}

.person-added {
	margin-top: 30px;
	text-align: center;
}

.person-added__name {
	margin: 0;
	color: #54575a;
	font-family: 'HumanistSlabserif712 BT Black', sans-serif;
	font-size: 14px;
	font-weight: 900;
	text-transform: capitalize;
}

.person-added__description {
	margin: 0;
	color: #54575a;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
}
