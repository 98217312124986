.footer {
    border-top: 1px solid #54575a;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 30px;
    padding: 5px 0 30px 0;
}

.footer__text {
    color: #666666;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 10px;
}