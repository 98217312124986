.list-container {
	width: 100%;
	padding: 0 15px;
}

.list-actions-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.list__item {
	border-top: 1px solid #dee2e6;
	display: flex;
	align-items: flex-start;
	padding: .75rem 0 .7rem 0;
	width: 100%;
	cursor: pointer;
}

.list__item:last-child,
.list li:last-child a.list__item {
	border-bottom: 1px solid #dee2e6;
}

.list__item-content {
	padding-top: 2px;
	flex: 1 1 0;
	letter-spacing: 0.6px;
}

.list__item-name {
	padding-bottom: 4px;
	color: #000;
	font-family: 'FuturaStd Bold', sans-serif;
	font-size: .9rem;
	font-weight: 900;
	line-height: 1.3;
}

.list-actions-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.list__item-image-container {
	margin-right: 10px;
}

.list__item-image {
	width: 100%;
}

.list__item-icon {
	font-size: 36px;
}

.list__item-favorite-indicator {
	margin-top: -15px;
	margin-left: 20px;
}

.list__item-favorite-indicator i {
	color: white;
	font-size: 8px;
	background: orange;
	border-radius: 50%;
	padding: 1px;
	border: 1px solid white;
}
