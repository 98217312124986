div.modal--margin {
	margin: 0 auto;
}

.background-color-gray {
	background-color: #f1f1f1;
}

.form-create-case {
	padding: 0 15px;
}

.card {
	margin: 36px 0;
	padding-bottom: 8px;
	box-shadow: 4px 10px 16px #ccc;
	border-radius: 0 16px 0 16px;
	background-color: #fff;
}

.card__header {
	border-bottom: 1px solid #54575a;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 14px 16px;
}

.card__title {
	margin: 0;
	color: #b1b4b3;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 18px;
	letter-spacing: -0.4px;
}

.card__title--amount-selected {
	margin: 0 0 0 10px;
	
	color: #b1b4b3;
	font-size: 0.7rem;
	text-transform: uppercase;
}

.card--checkbox {
	overflow-y: scroll;
	height: 300px;
}

.card-form-container {
	padding: 0 16px 10px 16px;
	position: relative;
}

.checkbox {
	border-bottom: 1px solid #b1b4b3;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 15px 0;
	cursor: pointer;
}

.checkbox__information-contain {
	margin-left: 8px;
}

.checkbox__name {
	margin: 0;
	color: #000;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 1rem;
	text-transform: capitalize;
}

.checkbox__specialty {
	margin: 0;
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	font-size: 0.7rem;
	text-transform: uppercase;
}

.checkbox__case {
	margin: 0;
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	font-size: 0.7rem;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.input {
	border: none;
	border-bottom: 1px solid #b1b4b3;
	display: block;
	width: 100%;
	position: relative;
	z-index: 2;
	
    background-color: transparent;
	color: #000;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
	height: 30px;
	line-height: 30px;
	padding: 0 8px;
	font-size: 16px;
}

.input--textarea {
	border: 1px solid #b1b4b3;
	display: block;
	padding: 8px;
	width: 100%;
	color: #000;
	font-size: 14px;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.label {
	margin: 10px 0 5px 0px;
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	font-size: 12px;
}

.button {
	border: none;
	margin: 40px 0 15px 0;
	padding: 12px;
	width: 100%;
	background-color: #ffb500;
	color: #000;
	text-transform: uppercase;
	font-family: "Futura Std Book", sans-serif;
}

.search-container {
	padding: 0 16px;
}

.requiredError input {
	border-bottom: 1px solid red;
	color: red;
}

.requiredError label {
	color: red;
}

.requiredError h3 {
	color: red;
}

.inputCheckbox {
	margin-right: 10px;
	accent-color: #ffb500;
}
