.form-edit-and-create-survey-container {
	margin: 0 auto !important;
}

.form-edit-and-create-survey {
	padding: 25px 16px;
	position: relative;
}

.form-survey__card {
	margin-bottom: 30px;
	background-color: #fff;
	border-radius: 5px 16px 5px 16px;
	box-shadow: 4px 10px 16px #ccc;
}

.form-survey__card-title-container {
	border-bottom: 1px solid #54575a;
	padding: 10px 16px;
	position: relative;
	display: flex;
	align-items: center;
}

.form-survey__card-title {
	display: inline-block;
	margin: 0;
	color: #b1b4b3;
	font-size: 16px;
	font-family: "Futura Std Bold", sans-serif;
}

.form-survey__card-label-settings {
	border-bottom: 1px solid #b1b4b3;
	padding: 0 0 5px 0;
}

.form-survey__card-title-settings {
	border-bottom: 1px solid #b1b4b3;
}

.form-survey__card-menu-icon {
	position: absolute;
	right: 15px;
	font-size: 24px;
}

.form-survey__card-settings-container {
	padding: 10px 16px;
}

.form-survey__card-settings-option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #b1b4b3;
	padding: 10px 0;
}

.form-survey__card-settings-option-label {
	margin: 0;
	font-size: 14px;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	opacity: 0.3;
}

.form-survey__card-settings-option-label--enabled {
	opacity: 1;
}

.form-survey__card-settings-icon-container {
	display: inline-flex;
	margin: 0 10px 0 0;
	font-size: 20px;
}

.form-survey__card-single-answer-icon-container {
	cursor: pointer;
}

.form-survey__card-toggle-container {
	display: flex;
	order: 1;
}

.form-survey__card-group {
	display: flex;
	flex-direction: column;
	padding: 10px 16px;
}

.form-survey__card-label {
	margin: 0;
	color: #54575a;
	font-size: 8px;
	font-family: 'Futura Std Book', sans-serif;
	letter-spacing: 0.4px;
	text-transform: uppercase;
}

.form-survey__card-input {
	border: none;
	border-bottom: 1px solid #b1b4b3;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
}

.form-survey__card-textarea {
	border: 1px solid #b1b4b3;
	margin-top: 5px;
	padding: 8px;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
}

.form-survey__card-question-number {
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	margin-right: 10px;
	width: 30px;
	background-color: #535659;
	color: #fff;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 12px;
}

.form-survey__arrow-down-icon {
	margin-left: 10px;
}

.form-survey__card-answers-container {
	padding: 10px 16px;
}

.form-survey__card-single-answer {
	display: flex;
	align-items: center;
	margin: 10px 0 0 0;
}

.form-survey__card-single-answer-label {
	margin: 0;
}

.form-survey__card-single-answer-input-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	border-bottom: 1px solid #b1b4b3;
}

.form-survey__card-single-answer-select {
	width: 100%;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	background-color: #fff;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
}

.form-survey__card-answers-container-margin .form-survey__card-single-answer-input-container {
	margin: 0 0 0 10px;
}

.form-survey__card-single-answer-input {
	border: none;
	flex: 1;
	font-size: 14px;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form-survey__card-button {
	background-color: #fff;
	border: 1px solid #54575a;
	margin: 25px 0 0 0;
	padding: 6px 12px;
	width: 100%;
	color: #54575a;
	cursor: pointer;
	font-family: "Futura Std Book", sans-serif;
	letter-spacing: 0.6px;
	text-transform: uppercase;
}

.form-survey__add-question-wrapper {
	position: fixed;
	bottom: 20px;
	width: 100%;
	max-width: 500px;
	padding-right: 20px;
	pointer-events: none;
}

.form-survey__add-button-wrapper {
	width: min-content;
	float: right;
}

.form-survey__add-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	height: 40px;
	width: 40px;
	background-color: #fdb42b;
	border-radius: 50%;
	color: #fff;
	font-size: 26px;
}

.form-survey__buttons-container {
	margin-bottom: 5px;
	padding: 10px;
	width: 200px;
	margin-left: auto;
	background-color: #fff;
	box-shadow: 0 0 10px #0000004d;
}

.form-survey__buttons-container hr {
	margin-top: .5rem;
	margin-bottom: .5rem;
}

.form-survey__button {
	border: none;
	display: block;
	padding: 0;
	margin-left: auto;
	background-color: #fff;
	font-family: "Futura Std Book", sans-serif;
}

.hidden {
	display: none;
}
