.card-verbatims-container {
	padding: 10px 16px;
}

.card-verbatim {
	border: 1px solid #b1b4b3;
	padding: 2px 10px 10px 10px;
	position: relative;
	margin: 10px 0;
}

.card-verbatim__remove-icon {
	position: absolute;
	top: 8px;
	right: 8px;
}

.card-verbatim__label {
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	font-size: 12px;
}

.card-verbatim__label-input {
	margin: 10px 0 5px 0px;
}

.card-verbatim__label-textarea {
	margin: 16px 0 5px 0;
}

.button {
	background-color: transparent;
	border: 1px solid #54575a;
	padding: 6px 12px;
	width: 100%;
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	text-transform: uppercase;
}
