.surveys-and-evaluations {
	padding-top: 80px;
}

.surveys-and-evaluations__title {
	border-bottom: 1px solid #54575a;
	padding: 15px 0;
	margin: 0;
	font-family: "Futura Std Bold", sans-serif;
	text-align: center;
	font-size: 30px;
}

.surveys-and-evaluations__information-container {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #b1b4b3;
	padding: 15px;
}

.surveys-and-evaluations__information {
	width: 50%;
	margin-bottom: 10px;
}

.surveys-and-evaluations__information:last-child {
	margin: 0;
}

.surveys-and-evaluations__information-label {
	color: #54575a;
	font-family: 'Futura Std Book', sans-serif;
	text-transform: uppercase;
	font-size: 13px;
}

.surveys-and-evaluations__information-text {
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.surveys-and-evaluations__actions {
	padding: 15px;
}

.surveys-and-evaluations__action {
	border: 1px solid #54575a;
	display: flex;
	align-items: center;
	min-height: 50px;
	margin-bottom: 15px;
	padding: 0px;
	width: 100%;
	background-color: transparent;
	border-radius: 8px;
}

.surveys-and-evaluations__action-icon {
	border-right: 1px solid #54575a;
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 15px;
	color: #54575a;
	font-size: 20px;
}

.surveys-and-evaluations__action-icon-red {
	border-right: 1px solid #54575a;
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 15px;
	background: #c60000;
	color: white;
	font-size: 20px;
	min-height: 50px;
}

.surveys-and-evaluations__action-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	height: 100%;
	padding: 8px;
}

.surveys-and-evaluations__action-label-text {
	margin: 0;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 21px;
}
