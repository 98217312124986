.create-engagement-emails__buttons {
    display: flex;
    justify-content: space-between;
    padding: 45px 15px;

    background-color: #f1f1f1;
}

.create-engagement-emails__button {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 46px 20px 46px 10px;

    background-color: #fff;
    border: 2px solid #ffb500;
    border-radius: 26px;
    height: 60px;
}

.create-engagement-emails__button--disabled {
    border: 2px solid #e5e6e8;
}

.create-engagement-emails__button--disabled .create-engagement-emails__button-icon {
    color: #b5b5b7;
}

.create-engagement-emails__button--disabled .create-engagement-emails__button-label {
    color: #949494;
}

.create-engagement-emails__button--disabled .create-engagement-emails__button-description {
    color: #868686;
}

.create-engagement-emails__button:last-child {
    margin-left: 15px;
}

.create-engagement-emails__button-icon {
    font-size: 50px;
}

.create-engagement-emails__button-text {
    margin-left: 10px;

    line-height: 1;
    text-align: left;
}

.create-engagement-emails__button-label {
    font-family: "EgyptienneURWLig", sans-serif;
}

.create-engagement-emails__button-description {
    font-family: "Futura Std Bold", serif;
}

.email-preview-modal {
    max-height: 500px;
    padding: 0 !important;

    overflow-y: auto;
}

.close-modal-container {
    padding: 10px 15px 0 0;

    text-align: right;
}

.close-modal-button {
    background-color: transparent;
    border: none;
    padding: 0;

    font-size: 16px;
}