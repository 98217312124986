.collateral-container {
    margin-top: 70px;
    height: calc(100vh - 70px);

    background-color: #f1f1f1;
}

.collateral-header {
    margin: 0;
    padding: 0 0 4px 15px;
    font-size: 24px;
    font-weight: normal;
    font-family: 'EgyptienneURWLig';
}

.collateral-list {
    border-top: 1px solid #e7e6e9;
    border-bottom: 1px solid #e7e6e9;

    background-color: #fff;
}

.collateral-list-item {
    border-bottom: 1px solid #e7e6e9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;
    padding: 12px 15px 12px 0;

    color: #000;
}

.collateral-list-item:last-child {
    border-bottom: none;
}

.collateral-item-left {
    line-height: 1.2;
}

.collateral-item-title {
    font-family: "Futura Std Bold", sans-serif;
    font-size: 16px;    
}

.collateral-item-subtitle {
    color: #b1b4b3;
    font-family: "Futura Std Book", sans-serif;
    font-size: 14px;
}