.pagelink{
    padding: 3px 9px 3px 9px;
    color: #000;
    background-color: #fff;
    border: 1px solid #c2c2c2;
    border-radius: 20px;
    margin: 5px;
    font-size: .8rem;
    transition: .3s ease-in-out;
    cursor: pointer;
}
.pagelink.active{
    color: #fff;
    background-color: #999;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    /* position: absolute; */
    border-radius: .25rem;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    padding-bottom:15px;
    background:white;
}
