body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'EgyptienneURWLig';
  src: local('EgyptienneURWLig'), url(./assets/fonts/EgyptienneURWLig/EgyptienneURWLig.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaStd';
  src: local('FuturaStd'), url(./assets/fonts/FuturaStd/FuturaStd-Book.ttf) format('truetype');
  src: local('FuturaStd'), url(./assets/fonts/FuturaStd/FuturaStd-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'FuturaStd Book';
  src: local('FuturaStd'), url(./assets/fonts/FuturaStd/FuturaStd-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'FuturaStd Bold';
  src: local('FuturaStd'), url(./assets/fonts/FuturaStd/FuturaStd-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'HumanistSlabserif712BT Roman';
  src: local('HumanistSlabserif712BT'), url(./assets/fonts/HumstSlab712BT/HumstSlab712BT-Roman.ttf) format('truetype');
}

@font-face {
  font-family: 'HumanistSlabserif712BT';
  src: local('HumanistSlabserif712BT'), url(./assets/fonts/HumstSlab712BT/HumstSlab712BT-Black.ttf) format('truetype');
  src: local('HumanistSlabserif712BT'), url(./assets/fonts/HumstSlab712BT/HumstSlab712BT-Roman.ttf) format('truetype');
}


