.survey-edit label{
font-size: .6rem;
font-weight: 100;
color: #888888;
}
.form-control2 {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem 0 .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing:1px;
    color:black;
    background-color:transparent;
    background-clip: padding-box;
    border:0;
    border-bottom: 1px solid #cecccc;
    font-family: "EgyptienneURWLig", sans-serif;
}
.form-control2:focus {
    color: #000000;
}
.q-textarea{
    font-family: "EgyptienneURWLig", sans-serif;
    font-size:14px;
    color:black;
    font-weight:600;
}
.bubble{
    background-color: #5b5b5b;
    padding:6px 9px 5px 9px;
    border-radius:14px;
    font-size: 10px;
    color:white;
    margin-right: 10px;
}
.dots-survey{
    float: right;
    font-size: 1.3rem;
    padding-top: 2px;
    color: #333333;
}
.edit-survey-settings,
.edit-survey-settings.line{
    padding:8px 0;
    border-bottom: 1px solid #cecccc;
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 13px;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 2.2;
    color: #3e3e3e;
}
.edit-survey-settings.line{
    border-top: 1px solid #cecccc;
}
.edit-survey-settings::before{
    border-bottom: 1px solid #cecccc;
}
.edit-survey-settings img{
   width:20px;
   height:auto;
   margin-right:8px;
}
img.switch{
    width: 35px;
    float:right;
    margin-top:3px;
    margin-right: 0;
}
.multi-choice-ans{
    display:flex;
}
.multi-choice-ans input{
    width: 100%;
    margin-right: -15px;
    margin-top:-15px;
}
.rating{
    display:flex;
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 13px;
    color:black;
    margin-top: 8px;
    padding-left:
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: black; 
}
.btn-large-add-answer{
    background-color: #ffffff;
    border: 1px solid #8a8a8a;;
    padding: 10px 82px;
    max-width:100%;
    font-size: 1rem;
    border-radius: 0;
    margin: 0 auto;
    color:#5b5b5b;
    letter-spacing:1.2px;
}
.recomm{
    margin-left:-5px;
    margin-top: -15px;
    margin-right: -15px;
}
.align-center{
    text-align: center;
}