.header {
    display: flex;
    padding-left: 50px;
}

.header__left {
    padding: 0 15px 0 0;
    width: 50%;
}

.header__text-light {
    font-family: "EgyptienneURWLig", sans-serif;
}

.header__image-container {
    margin-bottom: 15px;
}

.header__image {
    width: 100%;
}

.header__right {
    width: 50%;
}

.header__heading {
    font-size: 38px;
    line-height: 40px;
}

.header__description {
    font-family: "Futura Std Bold", sans-serif;
    font-size: 18px;
    line-height: 1;
}

.paragraphs-container {
    padding: 30px 15px 15px 50px;
}

.section {
    display: flex;
    padding: 50px 0 90px 0;
    position: relative;
}

.section--background-image {
    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.section__item {
    flex: 1 1 auto;
}

.section__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;    
}

.section__image {
    width: 80%;
}

.section__text-container {
    margin-left: 30px;
}

.section__number {
    color: #545857;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 65px;
    line-height: 1;
}

.section__number--light {
    color: #BBBCBC;
}

.section__text-light {
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 26px;
    line-height: 32px;
}

.section__text-bold {
    font-family: "Futura Std Bold", sans-serif;
    font-size: 26px;
    line-height: 32px;
}

.section__point-of-contact {    
    color: #000;
    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
    font-size: 16px;
    line-height: 32px;
}

.section__point-of-contact-name {    
    color: #000000;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 16px;
    line-height: 32px;
}

.tutorial__text {
    padding-bottom: 20px;

    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
    font-size: 16px;
}

.tutorial__text--margin {
    padding: 0;
}

.tutorial__list {
    margin-left: 18px;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    list-style-type: disc;
}

.tutorial__list-item {
    line-height: 25px;
}

.tutorial__text--margin {
    margin: 0;
    list-style-type: none;
}

.list {
    margin: 0 0 0 30px;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.list--margin-right {
    margin: 0 10px 0 0;
}

.list__item {
    line-height: 25px;
}

.list-names {
    padding-left: 20px;
    
    color: #000000;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

@media (max-width: 550px) {
    .section {
        margin: 0 15px;
    }

    .section__image {
        display: none;
    }
}