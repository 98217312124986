.graph-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
}

.single-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
}

.single-bar__bar--small {
    margin: 0 2px;
}

.single-bar__bar {
    position: relative;
    height: 117px;
    width: 20px;
    overflow: hidden;

    background-color: #efeff3;
}

.single-bar__bar--small .single-bar__bar {
    height: 52px;
    width: 9px;
}

.single-bar__bar-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    background-color: #1d208c;
}

.single-bar__bar--small .single-bar__percentage, .single-bar__bar--small .single-bar__count {
    font-size: 6px;
}

.single-bar__percentage {
    margin: 10px 0 0 0;

    color: #54575a;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 14px;
}

.single-bar__count {
    color: #b1b4b3;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 14px;
}