.surveyeval__category-container {
	padding-top: 90px;
}

.surveyeval__type-chooser {
	display: flex;
	width: 100%;
	padding: 5px 15px;
}

.surveyeval__type-label {
	border: 1px solid black;
	background: white;
	color: black;
	cursor: pointer;
	border-radius: 15px;
	padding: 3px 10px;
	margin-right: 15px;
	font-size: .7rem;
	font-family: 'Futura Std Book';
}

.surveyeval__type-label-selected {
	background: #ffb500;
	color: white;
}

.tab-title-main {
	padding: 0 15px 15px 15px;
	border-radius: 0 0 0 20px;
	box-shadow: 0 9px 9px #e2e2e2;
	background-size: contain;
	background-color: #fff;
}
