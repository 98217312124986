.business-units-checkboxes-container {
    margin-bottom: 0;
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid #b7b7b7;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.business-units-checkboxes-container__label {
    margin: 0;

    cursor: pointer;
    font-family: "Futura Std Bold", sans-serif;
    font-size: 1.15rem;
    font-weight: 800;
}