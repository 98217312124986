.trial-details__information {
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.trial-summary__card-images-container {
	display: flex;
	flex-wrap: wrap;
}

.trial-summary__card-wrapper {
	margin: .8rem 6px 0 0;
}

.trial-summary__card-image {
	background-repeat: no-repeat;
	background-size: 35%;
	background-position: center;
	width: 100%;
	height: 75px;
}

.trial-summary__card-image--specialty {
	max-width: 35%;
	max-height: 100%;
}

.trial-summary__card-select-container--competitors .trial-summary__card-image {
	background-size: 60px;
}

.trial-summary__image-container {
	margin: .8rem 0 0 0;
	padding: 0 5px;
	width: 31.5%;
}

.trial-summary__card-select-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.trial-summary__select-card-image--contrast {
	-webkit-filter: contrast(0);
	filter: contrast(0);
}

.trial-summary__card-select-image--competitors {
	max-width: 60px;
	max-height: 100%;
}

.trial-summary__card-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Futura Std Bold", sans-serif !important;
}

.trial-summary__pencil-icon {
	cursor: pointer;
}

.trial-summary__card-name {
	margin-top: 5px;
	font-size: 11px;
	font-family: "Futura Std Book", sans-serif;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
}

.trial-summary__card-name--active {
	color: #ffb500;
}

.trial-summary__card-image--active {
	border: 1px solid #ffb500 !important;
}

.trial-summary__card-complete-button {
	background-color: transparent;
	border: none;
	color: #ffb500;
	cursor: pointer;
	font-family: "Futura Std Book", sans-serif;
	font-size: 16px;
}

.trial-summary__people-description {
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
}

.add-person-button-container {
	padding: 0 0 12px 0;

	text-align: right;
}

@media (min-width: 500px) {
	.trial-summary__card-image {
		height: 100px;
	}
}

@media (min-width: 600px) {
	.trial-summary__card-image {
		background-size: 20%;
	}
}