.circle {
    border-radius: 50%;
    
    height: 10px;
    width: 10px;
    margin: 0 5px 0 0;
}

.circle--border {
    border: 1px solid #b1b4b3;
}

.circle-0 {
    background-color: #c1cbde;
}

.circle-1 {
    background-color: #90a3c4;
}

.circle-2 {
    background-color: #6784ac;
}

.circle-3 {
    background-color: #3e6a99;
}

.circle-4 {
    background-color: #0d5487;
}