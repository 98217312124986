.form {
	padding: 32px 30px 30px;
}

.form__label {
	border-bottom: 1px solid #abaead;
	margin: 0 0 30px 0;
	width: 100%;
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 31px;
}

.form__input {
	border: none;
	border-bottom: 1px solid #b1b4b3;
	width: 100%;
	color: #b1b4b3;
	font-size: 16px;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form__input:focus {
	color: #000;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 16px;
}

::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 16px;
}

:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 16px;
}

:-moz-placeholder {
	/* Firefox 18- */
	font-size: 16px;
}

.form__buttons-container {
	margin: 35px 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.form__button {
	border: none;
	height: 40px;
	min-width: 120px;
	font-size: 17px;
	font-family: "Futura Std Book", sans-serif;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.form__button--cancel {
	background-color: transparent;
	color: #b1b4b3;
	font-size: .8rem;
}

.form__button--submit {
	background-color: #b1b4b3;
}

.form__button--cansubmit {
	background-color: #b83c27;
	color: white;
	margin-bottom: 1rem;
	font-size: .8rem;
}
