.form-edit-case__search-to-add {
    position: relative;

    background-color: #fff;
}

.form-add-surgeon__label {
    margin: 0;
    padding: 0 0 8px 15px;
    display: block;

    background-color: #f0f0f4;
    color: #54575a;
    font-family: "Futura Std Book", serif;
    font-size: 14px;
}

.form-add-surgeon__container-input {
    border-top: 1px solid #54575a;
    padding: 10px 15px;
}

.form-add-surgeon__input {
    border: 1px solid #b1b4b3;
    border-radius: 6px;
    padding: 4px 0 4px 8px;
    width: 100%;

    color: #54575a;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form-add-surgeon__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 16px;
}

.form-add-surgeon__input::-moz-placeholder { /* Firefox 19+ */
  font-size: 16px;
}

.form-add-surgeon__input:-ms-input-placeholder { /* IE 10+ */
  font-size: 16px;
}

.form-add-surgeon__input:-moz-placeholder { /* Firefox 18- */
  font-size: 16px;
}