.form {
    padding: 32px 50px 30px 50px;
}

.form__title {
    border-bottom: 1px solid #abaead;
    margin: 0;
    padding: 0 0 5px 0;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 31px;
    line-height: 31px;
}

.form__sub-title {
    border-bottom: 1px solid #e1e1e1;
    margin: 36px 0 0 0;
    padding: 0 0 8px 0;

    font-family: "Futura Std Book", sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
}

.form__group {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    padding: 12px 20px;
}

.form__label {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 20px;

    cursor: pointer;
    font-family: "Futura Std Book", sans-serif;
    font-size: 17px;
}

.form__chart-label {
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
    
    cursor: pointer;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 17px;
    line-height: 1;
}

.form__label-text {
    margin: 0 0 0 10px;
}

.form__option {
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 15px;

    cursor: pointer;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form__option-icon {
    font-size: 25px;
}

.form__select-light {
    color: #b1b4b3;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 16px;
    line-height: 1;
}

.form__select-text {
    padding: 5px 0 0 0;

    font-family: "Futura Std Book", sans-serif;
    font-size: 16px;
    color: #000000;
    line-height: 1;
}

.form__buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0 20px 0;
}

.add-trial-statistic__label  {
    margin: 15px 0 0 0;
    width: 100%;
    
    cursor: pointer;
}

.add-trial-statistic__input {
    border: none;
    height: 30px;
    padding: 0 12px;
    width: 100%;

    border-radius: 0;
    font-size: 16px;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    -webkit-box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
    -moz-box-shadow: inset 0px 0px 6px 0px rgba(0,0,0,0.25);
    box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
}

.form__chart-container {
    width: 100px;
}