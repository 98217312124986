.form-create-email-name__label {
    margin: 0;
    padding: 10px 15px;

    display: block;
    font-size: 30px;
    font-family: "EgyptienneURWLig", sans-serif;
}

.form-create-email-name__input {
    border: none;
    border-top: 1px solid #d9d9d9;
    padding: 15px;
    width: 100%;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form-create-email-name__input::placeholder {
    color: #b1b4b3;
    opacity: 1 !important;
}

/* Chrome/Opera/Safari */
.form-create-email-name__input::-webkit-input-placeholder { 
    color: #b1b4b3;
    font-size: 16px;
    opacity: 1 !important;
}

/* Firefox 19+ */
.form-create-email-name__input::-moz-placeholder {
    color: #b1b4b3;
    font-size: 16px;
    opacity: 1 !important;
}

/* IE 10+ */
.form-create-email-name__input:-ms-input-placeholder { 
    color: #b1b4b3;
    font-size: 16px;
    opacity: 1 !important;
}

/* Firefox 18- */
.form-create-email-name__input:-moz-placeholder { 
    color: #b1b4b3;
    font-size: 16px;
    opacity: 1 !important;
}

.form-create-email-name__button {
    border: none;
    border-top: 1px solid #d9d9d9;
    display: block;
    padding: 15px 0;
    width: 100%;

    background-color: #fff;
    color: #54575a;
    font-family: "Futura Std Book", sans-serif;
    text-transform: uppercase;
}

.form-create-email-name__button--submit {
    color: #ffb500;
}