.edit-surgeon-information {
    margin-top: 64px;

    background-color: #f0f0f4;
}

.edit-surgeon-information__title {
    border-bottom: 1px solid #a7a7aa;
    padding: 20px 0 4px 0;
    margin: 0 15px 15px 15px;

    font-size: 1.5rem;
    font-family: "EgyptienneURWLig", sans-serif;
}

.form-edit-case-information__title {
    margin: 0;
    padding: 50px 0 8px 15px;

    border-bottom: 1px solid #54575a;
    color: #54575a;
    font-family: "Futura Std Book", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
}

.form-edit-case-information__container {
    border-bottom: 1px solid #c8c7cc;

    background-color: #fff;
}

.form-edit-case-information__group {
    border-bottom: 1px solid #c8c7cc;
    margin-left: 15px;
    padding: 14px 10px 14px 0;
}

input.form-edit-case-information__input {
    border: none;
    width: 100%;
    padding: 0;
    position: relative;
    z-index: 2;

    background-color: transparent;
    color: #54575a;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.form-edit-case-information__textarea-container {
    border: 1px solid #c8c7cc;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-top: 10px;

    color: #54575a;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

/* Chrome/Opera/Safari */
.form-edit-case-information__input::-webkit-input-placeholder { 
    font-size: 16px;
}

/* Firefox 19+ */
.form-edit-case-information__input::-moz-placeholder { 
    font-size: 16px;
}

/* IE 10+ */
.form-edit-case-information__input:-ms-input-placeholder { 
    font-size: 16px;
}

/* Firefox 18- */
.form-edit-case-information__input:-moz-placeholder { 
    font-size: 16px;
}

.equipment-container {
    border-bottom: 1px solid #c8c7cc;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px 15px 15px;

    background-color: #fff;
}

.equipment {
    border-radius: 15px;
    display: flex;
    align-items: flex-end;
    height: 100px;
    margin-top: 15px;
    padding-left: 9px;
    padding-bottom: 5px;
    width: 30%;

    color: #fff;
    font-family: "Futura Std Book", sans-serif;
}

.form-edit-case__buttons {
    margin-top: 40px;
    padding-bottom: 10px;
}

.button {
    background-color: #fff;
    border: none;
    border-top: 1px solid #c8c7cc;
    border-bottom: 1px solid #c8c7cc;
    display: block;
    height: 50px;
    margin-bottom: 20px;
    width: 100%;

    font-family: "Futura Std Book", sans-serif;
    text-transform: uppercase;
    transition: 0.3s ease opacity;
}

.form-edit-case__save {
    color: #ffb500;
}

.form-edit-case__delete {
    color: #bc452f;
}

.form-edit-case__save:hover,
.form-edit-case__delete:hover {
    opacity: 0.75;
}
.inputCheckbox {
	margin-right: 10px;
	accent-color: #ffb500;
}
