.container {
    margin: 70px 0 0 0;
}

.header--wrapper {
    -webkit-box-shadow: 0 6px 6px -6px rgb(0 0 0 / 23%);
    box-shadow: 0 6px 6px -6px rgb(0 0 0 / 23%);
}

.header {
    margin: 0 0 15px 0;
    padding: 0 15px 16px 15px;
}

.header__title {
    margin: 0;

    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 42px;
}

.header__buttons {
    text-align: right;
}

.header__button {
    height: 23px;
    padding: 2px 10px;

    background-color: transparent;
    border: 1px solid #54575a;
    border-radius: 20px;
    color: #54575a;
	font-family: "Futura Std Book", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}

.header__button--disabled {
    border-color: #b1b4b3;
    color: #b1b4b3;
}

.header__button:first-child {
    margin: 0 20px 0 0;
}

.form-menu {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 20px 15px;

    -webkit-box-shadow: 0 6px 6px -6px rgb(0 0 0 / 23%);
    box-shadow: 0 6px 6px -6px rgb(0 0 0 / 23%);
}

.form-menu__back-button, .form-menu__next-button {
    border: none;
    display: flex;
    align-items: center;
    padding: 0;

    background-color: transparent;
    font-family: "Futura Std Book", sans-serif;
    font-size: 13px;
    text-transform: uppercase;
}

.form-menu__next-button--send {
    padding: 0 15px;

    background: #ffb500;
    border-radius: 30px;
    color: #000;
    font-family: "Futura Std Book", sans-serif;
    font-size: 16px;
}

.form-menu__navigation-icon {
    color: #ffb500;
    font-size: 20px;
}

.form-menu__navigation-icon--white {
    color: #fff;
}

.form-menu__icons {
    display: flex;
}

.form-menu__icon {
    border: 2px solid #B1B4B3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-right: 5px;
    width: 40px;
    
    border-radius: 50%;
    color: #B1B4B3;
    font-size: 25px;
}

.form-menu__icon--active {
    border: 2px solid #ffb500;
    color: #ffb500;
}

.section {
    padding: 30px 15px 45px 15px;
}

.section__text {
    color: #54575a;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 16px;
}

.review-and-confirm__person-name {
    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
}

.form {
    padding: 0 15px 0 15px;
}

.form-group {
    margin: 0 0 45px 0;
}

.label {
    display: flex;
    align-items: center;
    margin: 0 0 26px 0;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
}

.label--padding {
    padding: 0 15px 0 15px;
}

.label__horizontal-line {
    flex: 1;
    height: 1px;
    margin: 0 0 0 20px;

    background-color: #54575a;
}

.input {
    border: none;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    width: 100%;
    
    -webkit-box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
    -moz-box-shadow: inset 0px 0px 6px 0px rgba(0,0,0,0.25);
    box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
    font-size: 16px;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.input__error {
    display: block;
    margin: 0 0 8px 0;

    color: #B83C27;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 12px;
}

.preview-wrapper-box-shadow {
    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
    padding-bottom: 20px;
}

.preview__header {
    display: flex;
    justify-content: space-between;
    padding: 0 15px 0 15px;
}

.preview__header-left {
    width: 50%;

    font-size: 16px;
}

.preview__header-title-textarea {
    display: block;
    margin: 0 0 8px 0;
    width: 100%;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 16px;
}

.preview__header-headline-textarea {
    display: block;
    margin: 0 0 8px 0;
    width: 100%;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 16px;
}

.preview__subsection {
    display: flex;
    flex-direction: column;
    margin: 0 0 50px 50px;
}

.pre-trial-subsection-wrapper{
    margin: 15px 15px 12px 0;
}

.preview__subsection-textarea {
    padding: 5px 15px 5px 15px;
    width: 100%;

    font-family: "Futura Std Bold", sans-serif;
    font-size: 16px;
}

.email-preview__footer {
    margin: 0 15px;
}

.second-view__container {
    margin: 0 15px;
}

.second-view__buttons {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 10px 0;
}

.second-view__button {
    border: none;
    padding: 0;
    position: relative;

    background-color: transparent;
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 17px;
}

.second-view__button.second-view__button--active::before {
    content: " ";
    width: 25px;
    height: 17px;
    position: absolute;
    left: 50%;
    top: -15px;
    margin-left: -12px;

    background: url('/images/email/arrow.png') no-repeat;
}

.second-view__input-container {
    border-top: 1px solid #dee2e6;
    min-height: 80px;
    padding: 7px 12px;
    
    -webkit-box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
    -moz-box-shadow: inset 0px 0px 6px 0px rgba(0,0,0,0.25);
    box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 25%);
}

.second-view__input {
    border: none;
    width: 100%;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.add-by-email-tags__tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    padding: 10px 15px;
    width: 100%;

    background-color: #ffb500;
    border-radius: 3px;
    color: #ffffff;
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-weight: 700;
    font-size: 15px;
    word-break: break-all;
}

.add-by-email-tags__remove-button {
    border: none;
    margin: 0 0 0 8px;
    padding: 0;

    background-color: transparent;
    color: #fff;
    font-size: 20px;
}

.email-preview__label {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.email-preview__textarea {
    margin: 0 0 8px 0;
    width: 100%;
}

.overlay {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    background-color: #ffffff;
    opacity: 0.7;
}

.toggle-container {
    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
    padding: 0 0 20px 0;
    margin: 10px 0 50px 0;

    text-align: center;
}

.toggle {
    z-index: 1;
    margin-top: 40px;
}


.customize-email__add-dashed-button-container {
    padding: 56px 30px 20px 30px;
    position: relative;

    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.customize-email__add-dashed-button-container--padding {
    padding: 56px 30px 0 0;
}

.customize-email__add-a-statistic-container {
    padding: 40px 0 20px 0;
    position: relative;

    background: url(/images/email/shadow-img.gif) left bottom no-repeat;
}

.customize-email__add-a-statistic-container--remove-background-image {
    background-image: none;
}

.customize-email__add-a-statistic-button {
    border: 1px dashed #b1b4b3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
    width: 47%;

    background-color: transparent;
    border-radius: 25px;
    color: #b1b4b3;
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 25px;
}

.customize-email__add-a-statistic-button-icon {
    margin: 0 10px 0 0;
    width: 25px;
}

.customize-email__textarea {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
}

.inservice-attendees-number {
    color: #545857;
}

.pre-trial__video-link {
    display: block;
    margin: 0 50px 30px 50px;
}

.pre-trial__video-link-image {
    width: 100%;
}

.weekly-engagement__dashed-button-container {
    margin: 40px 0 0 30px;
}

.weekly-engagement__charts-preview {
    margin: 40px 0 0 0;
}