.cases-stats-container {
	box-shadow: 0px 20px 40px 0px rgba(35, 31, 32, 0.2);
	border-bottom-left-radius: 20px;
	margin-top: 72px;
}

.cases-stats-header {
	border-bottom: 1px solid #54575a;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0 10px 0;
}

.cases-stats-header__title {
	margin: 0 0 0 15px;
	font-size: 30px;
	font-family: "EgyptienneURWLig", sans-serif;
}

.cases-stats-header__image-container {
	margin: 0 15px 0 0;
	padding: 5px 0;
	display: flex;
	align-items: center;
}

.cases-stats-header-download {
	margin-left: 10px;
	cursor: pointer;
}

.cases-stats-header-download i {
	margin-left: 10px;
	color: #ffb500;
	font-size: 25px;
	-webkit-text-stroke: medium;
}

.case-stat-item {
	border-bottom: 1px solid #b1b4b3;
	display: flex;
	margin: 0 0 0 15px;
	padding: 0 15px 0 0;
}

.case-stat__image-container {
	margin-right: 20px;
	padding: 0;
	width: 10%;
}

.case-stat__image {
	width: 100%;
}

.cases-stats__total {
	display: flex;
	align-items: center;
	padding: 10px 15px 5px 15px;
	font-size: 22px;
}

.cases-stats__total-left {
	color: #54575a;
	font-family: "Futura Std Bold", sans-serif;
}

.cases-stats__total-text {
	color: #b1b4b3;
	font-family: "Futura Std Book", sans-serif;
}
