.text-block{
  border-top: 1px solid #838383;
  background-color: #fff;
  background-size: contain;
  margin-top: 35px;
  padding: 8px 0;
}
.text-block.transparent {
    background-color: transparent;
}
.text-block .question{
  font-family: 'HumanistSlabserif712BT-black';
  font-size: .8rem;
  margin-bottom: 10px;
}
.question.pie-line {
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 10px;
}
form label {
    font-family: "Futura", serif;
    font-weight: 100;
    font-size: .7rem;
}
.style-a {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: 5px 0 4px;
}
.right {
    margin-left: 100%;
}
.bubble-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
input[type=radio] {
    width: 16px;
    height: 16px;
    opacity:.7;
}
.q-block {
    border-top: 1px solid #808080;
    background-color: white;
    margin-bottom: 40px;
    padding: 10px 15px;
}
.question{
    font-family: 'HumanistSlabserif712BT-black';
    font-size: .8rem;
}
.ans-option {
    font-family: 'HumanistSlabserif712BT-lt';
    font-size: .9rem;
    display: inline-block;
    margin: 10px 0 15px 10px;
    line-height: 1;
    vertical-align: middle;
}
.ans-selection {
    border-top: 1px solid #cecccc;
    justify-content: center;
    padding-top: 10px;
}
.radio-select {
    margin-top: 5px;
}
.survey-ans {
    padding: 9px 0 18px;
    align-items: top;
    border-top: 1px solid #d8d8d8;
}
.survey-ans-pie {
    padding: 9px 0 0px;
    align-items: top;
    border-top: 1px solid #d8d8d8;
}
.form-control.survey-textarea {
    border: 1px solid #fff !important;
    font-family: 'HumanistSlabserif712BT-Roman';
    font-size: 13px;
}
.form-survey {
    margin-bottom: 80px;
}
.btn-large {
    background-color: #f0b43f;
    border: none;
    padding: 12px 110px;
    font-size: 1rem;
    border-radius: 0;
    color: #333;
    margin: 0 auto;
}
.btn-large-edit {
    background-color: #f0b43f;
    border: none;
    padding: 12px 126px;
    font-size: 1rem;
    border-radius: 0;
    color: #333;
    margin: 0 auto;
    opacity: .5;
}
.btn-large-coral {
    background-color: #b83c27;
    color: white;
    border: none;
    padding: 12px 116px;
    font-size: 1rem;
    border-radius: 0;
    margin: 0 auto;
}
.preview {
    position: fixed;
    font-weight: 100;
    font-family: "EgyptienneURWLig", sans-serif;
    text-align: center;
    position: relative;
    padding: 15px 35px 15px 0;
    color: #fff;
    margin: auto;
}
.tab-navbar-preview {
    background-color: #545659;
    background-size: contain;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 25;
}
.close-preview {
    font-family: "Futura", serif;
    font-size: 28px;
    font-weight: 100;
    color: white;
    margin-top: 6px;
    padding-left: 15px;
}
.ans-textarea{
    border: none;
    width:100%;
}
.form-survey{
    width:100%;
}
.form-survey.preview{
    padding-top:60px;
}