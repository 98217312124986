.tab-audio{
  background-color: #fff;
  border-radius: 4px 0 0 20px;
  margin: 35px -15px 35px 0;
  padding: 15px;
  box-shadow: 4px 10px 16px #ccc;
  background-size: contain;
}
.tab-button{
  background-color: #55575a;
  margin: 0 -15px -15px;
  border-radius: 0 0 0 20px;
  text-align: center;
  color: #e9e9e9; 
  padding: 6px 0;
  font-size: .8rem;
  transition: .3s ease-in-out;
}
.tab-button:hover{
  background-color: #000;
}
.tab-button a {
    color: white;
}
.tab-button a:hover {
    color: white !important;
}
.audio-img img{
  width: 40px;
}
.length{
  color: #333;
  font-size: .6rem;
  font-weight: 100;
}
.uploaded {
color: #b1b1b1;
}
.icon-img img{
  width: 40px;
}