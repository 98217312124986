.modal {
    padding: 24px !important;
}

.modal__title {
    border-bottom: 1px solid #abaead;
    font-family: "EgyptienneURWLig", sans-serif;
}

.modal__profile-icon {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.modal__profile {
    display: flex;
    align-items: center;
    margin: 40px 0 35px 0;
}

.modal__information-container {
    margin-left: 15px;
}

.modal__email {
    margin: 0;

    color: #b1b4b3;
    font-family: "Futura Std Book", sans-serif;
    font-size: 14px;
}

.modal__name {
    margin: 0;

    color: #000;
    font-family: "Futura Std Book", sans-serif;
    font-size: 16px;
    text-transform: capitalize;
}

.modal__warning-text {
    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
}

.modal__warning-subtext {
    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.modal__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
}