.surgeon-information {
	margin-top: 70px;
}

.surgeon-information__header {
	border-bottom: 1px solid #54575a;
	display: flex;
	padding: 20px 15px;
}

.surgeon-information__header-right-container {
	margin-left: 16px;
}

.surgeon-information__header-name {
	margin: 0 0 5px 0;
	color: #000;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 1.2rem;
}

.surgeon-information__header-speciality {
	margin: 5px 0 0 0;
	color: #54575a;
	font-family: "Futura Std Book", serif;
	font-size: 12px;
	text-transform: uppercase;
}

.surgeon-information__cases-count {
	display: flex;
	border-bottom: 1px solid #c8c7cc;
	padding: 15px;
	position: relative;
}

.surgeon-information__cases-count-item {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.surgeon-information__cases-count-number {
	margin: 0 0 4px 0;
	color: #54575a;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 1.5rem;
}

.surgeon-information__cases-count-label {
	color: #54575a;
	font-family: "Futura Std Book", serif;
}

.view-profile-button-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	bottom: -10px;
	width: 100%;
}

.view-profile-button {
	display: flex;
	align-items: center;
	width: 120px;
	background-color: #54575a;
	color: #fff !important;
	font-family: "Futura Std Book", serif;
	font-size: 0.7rem;
	border-radius: 15px;
	text-transform: uppercase;
	padding: 5px;
	justify-content: center;
}

.view-profile-button:hover {
	color: #ffffff !important;
}

.view-profile-button-icon {
	margin-right: 4px;
	color: #fff;
	font-size: 20px;
}

.surgeon-information-case {
	border-bottom: 1px solid #c8c7cc;
	display: flex;
	margin: 0 0 0 15px;
	padding: 14px 10px 14px 0;
	color: #000;
	line-height: 1.3;
}

.surgeon-information-case__item-left {
	flex: 1;
}

.surgeon-information-case__item-right {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 19px;
}

.surgeon-information-case__procedure {
	margin: 0;
	font-family: "Futura Std Bold", sans-serif;
}

.surgeon-information-case__equipment {
	margin: 0;
	color: #54575a;
	font-family: "Futura Std Book", serif;
	font-size: 0.8rem;
}

.surgeon-information-case__date {
	margin: 0;
	color: #b1b4b3;
	font-family: "Futura Std Book", serif;
	font-size: 0.8rem;
}

.surgeon-information-case__username {
	margin: 0;
	color: #b1b4b3;
	font-family: "Futura Std Book", serif;
	font-size: 0.8rem;
}

.surgeon-information__case-header {
	border-bottom: 1px solid #54575a;
	display: flex;
	align-items: flex-end;
	height: 61px;
	padding: 5px 15px;
	background-color: #efeff4;
	text-transform: uppercase;
}

.surgeon-information__case-header-title {
	margin: 0;
	font-size: 0.8rem;
	color: #54575a;
}

.surgeon-information__case-header-title--color {
	color: #b1b4b3;
}

.profile-image__font {
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 22px;
}
