.three-circles {
	display: flex;
	height: 20px;
	align-items: center;

	cursor: pointer;
}

.three-circles__circle {
	height: 7px;
	width: 7px;
	border-radius: 50%;
	border: 1px solid #b3b6b6;
	margin: 0 1px;
}

.popover_option {
	cursor: pointer;
	font-family: 'Futura Std Book';
	padding: 10px 5px;
	margin: 0;
	width: 150px;
}
