.container {
    padding: 15px 15px 30px 15px;
}

.heading {
    font-family: "EgyptienneURWLig", sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
}

.text {
    margin-bottom: 14px;

    font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
    font-size: 14px;
}

.text--font {
    margin: 0;

    font-family: 'HumanistSlabserif712 BT Black', sans-serif;
}

.text-container {
    margin: 0 0 30px 0;
}

.button {
    border: none;
    padding: 12px 0 12px 0;
    width: 100%;

    font-family: 'Futura Std Book', sans-serif;
    text-transform: uppercase;
}

.button--submit {
    margin: 0 0 15px 0;

    background-color: #b83c27;
    color: #fff;
}

.button--cancel {
    border: 1px solid #b1b4b3;

    background-color: #fff;
}