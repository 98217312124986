.no-results-text {
    padding: 20px 0;
    width: 100%;

    color: #b1b4b3;
    font-family: "Futura Std Book", sans-serif;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
}